import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Keycloak from 'keycloak-js'

import axios from 'axios'
// import service from './utils/http';
axios.defaults.withCredentials = false;

Vue.prototype.axios = axios //挂载原型，全局使用
// Vue.prototype.service = service //挂载原型，全局使用
Vue.use(ElementUI);

Vue.config.productionTip = false

// keycloak init options
const initOptions = {
  url: 'https://auth.lab.supercomputing.link/auth',
  realm: 'co-com',
  clientId: 'storage',
  onLoad: 'login-required'
}

const keycloak = Keycloak(initOptions)

keycloak.init({ onLoad: initOptions.onLoad, promiseType: 'native',checkLoginIframe:false }).then((authenticated) => {

  if (!authenticated) {
    window.location.reload();
  } else {
    Vue.prototype.$keycloak = keycloak
    localStorage.setItem('token',keycloak.token)
    console.log('Authenticated')
  }


  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')


  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      // console.log('角色11',refreshed)
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        // console.log('Token not refreshed, valid for '
        //   + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(error => {
      console.log('Failed to refresh token', error)
    })
  }, 60000)

}).catch(error => {
  console.log('Authenticated Failed', error)
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
