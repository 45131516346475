import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/',
  //   redirect: '/login',
  //   hidden: true,
  //   component: () => import('@/views/login'),
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   // redirect: '/login',
  //   hidden: true,
  //   component: () => import('@/views/login'),
  // },
  // {
  //   path: '/',
  //   name: '概述',
  //   redirect: '/',
  //   component: () => import('@/components/Home'),
  //   children: [
  //     {
  //       path: '/',
  //       // name: '统一存储视图',
  //       name: '概述详情',
  //       component: () => import('@/components/unifiedStorageManagement/components/unifiedStorageView')
  //     },
  //   ]
  // },
  {
    path: '/',
    name: '统一存储管理',
    redirect: '/',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/',
        name: '存储系统管理',
        component: () => import('@/components/unifiedStorageManagement/components/storageSystemManagement'),
      },
      {
        path: '/home/storageSystemManagement/addStorageSystem',
        name: '存储系统管理新增',
        hidden: true,
        component: () => import('@/components/unifiedStorageManagement/components/addStorageSystem')
      },
      {
        path: '/home/storageSystemManagement/detailStorageSystem',
        name: '存储系统管理详情',
        hidden: true,
        component: () => import('@/components/unifiedStorageManagement/components/detailStorageSystem')
      },
      {
        path: '/home/userSpaceManagement',
        name: '存储空间管理',
        // name: '存储空间审核',
        component: () => import('@/components/dataSpaceManagement/components/userSpaceManagement')
      },
      {
        path: '/home/userSpaceManagement/addUserSpace',
        name: '存储空间新增',
        hidden: true,
        component: () => import('@/components/dataSpaceManagement/components/addUserSpace')
      },
      {
        path: '/home/userSpaceManagement/weekWord',
        name: '每周工作',
        hidden: true,
        component: () => import('@/components/dataSpaceManagement/components/weekWord')
      },
      {
        path: '/home/publicDatasetManagement',
        name: '公共数据集管理',
        component: () => import('@/components/dataSpaceManagement/components/publicDatasetManagement')
      },
      {
        path: '/home/publicDatasetManagement/addPublicDataset',
        name: '公共数据集新增',
        hidden: true,
        component: () => import('@/components/dataSpaceManagement/components/addPublicDataset')
      },
    ],
  },
  {
    path: '/home',
    name: '申请审核管理',
    component: () => import('@/components/Home'),
    children: [
      // {
      //   path: '/home/userSpaceManagement',
      //   name: '用户空间管理',
      //   // name: '存储空间审核',
      //   component: () => import('@/components/dataSpaceManagement/components/userSpaceManagement')
      // },
      // {
      //   path: '/home/userSpaceManagement/addUserSpace',
      //   name: '用户空间新增',
      //   hidden: true,
      //   component: () => import('@/components/dataSpaceManagement/components/addUserSpace')
      // },
      // {
      //   path: '/home/userSpaceManagement/weekWord',
      //   name: '每周工作',
      //   hidden: true,
      //   component: () => import('@/components/dataSpaceManagement/components/weekWord')
      // },
      {
        path: '/home/storageSpaceAudit',
        // name: '申请审核管理',
        name: '存储空间审核',
        component: () => import('@/components/dataSpaceManagement/components/storageSpaceAudit')
      },
      {
        path: '/home/applicationReviewManagement',
        // name: '申请审核管理',
        name: '公共数据集审核',
        component: () => import('@/components/dataSpaceManagement/components/applicationReviewManagement')
      },
      // {
      //   path: '/home/publicDatasetManagement',
      //   name: '公共数据集管理',
      //   component: () => import('@/components/dataSpaceManagement/components/publicDatasetManagement')
      // },
      // {
      //   path: '/home/publicDatasetManagement/addStorageSystem',
      //   name: '公共数据集新增',
      //   hidden: true,
      //   component: () => import('@/components/dataSpaceManagement/components/addPublicDataset')
      // },
    ],
  },
  {
    path: '/home',
    name: '存储空间管理',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/userManagement/storageSpaceView',
        name: '空间管理',
        component: () => import('@/views/userSpaceManagement/storageSpaceView')
      },
      // {
      //   path: '/userManagement/dataspaceView',
      //   name: '数据空间视图',
      //   component: () => import('@/views/userSpaceManagement/dataspaceView')
      // },
      {
        path: '/userManagement/addUserSpace',
        name: '用户空间新增',
        hidden: true,
        component: () => import('@/views/userSpaceManagement/addUserSpace')
      },
      {
        path: '/userManagement/updateUserSpace',
        name: '用户空间容量变更',
        hidden: true,
        component: () => import('@/views/userSpaceManagement/updateUserSpace')
      },
      // {
      //   path: '/userManagement/userDatasetManagement',
      //   name: '用户数据集管理',
      //   component: () => import('@/views/userSpaceManagement/userDatasetManagement')
      // },
      // {
      //   path: '/userManagement/userDatasetManagement/addUserDataset',
      //   name: '用户数据集新增',
      //   hidden: true,
      //   component: () => import('@/views/userSpaceManagement/addUserDataset')
      // },
      {
        path: '/userManagement/objectStorageSystem',
        name: '对象存储系统 OBS',
        component: () => import('@/views/userSpaceManagement/objectStorageSystem')
      },
      {
        path: '/userManagement/fileStorageSystemNFS',
        name: '文件存储系统 NFS',
        component: () => import('@/views/userSpaceManagement/fileStorageSystemNFS')
      },
      {
        path: '/userManagement/fileStorageSystemHDFS',
        name: '文件存储系统 HDFS',
        component: () => import('@/views/userSpaceManagement/fileStorageSystemHDFS')
      },
      {
        path: '/userManagement/fileStorageSystemLustre',
        name: '文件存储系统 Lustre',
        component: () => import('@/views/userSpaceManagement/fileStorageSystemLustre')
      },

    ],
  },
  {
    path: '/home',
    name: '用户数据管理',
    component: () => import('@/components/Home'),
    children: [
      // {
      //   path: '/userManagement/storageSpaceView',
      //   name: '存储空间视图',
      //   component: () => import('@/views/userSpaceManagement/storageSpaceView')
      // },
      {
        path: '/userManagement/dataspaceView',
        name: '数据管理',
        component: () => import('@/views/userSpaceManagement/dataspaceView')
      },
      // {
      //   path: '/userManagement/addUserSpace',
      //   name: '用户空间新增',
      //   hidden: true,
      //   component: () => import('@/views/userSpaceManagement/addUserSpace')
      // },
      // {
      //   path: '/userManagement/updateUserSpace',
      //   name: '用户空间容量变更',
      //   hidden: true,
      //   component: () => import('@/views/userSpaceManagement/updateUserSpace')
      // },
      {
        path: '/userManagement/userDatasetManagement',
        name: '数据集管理',
        component: () => import('@/views/userSpaceManagement/userDatasetManagement')
      },
      {
        path: '/userManagement/userDatasetManagement/addUserDataset',
        name: '数据集新增',
        hidden: true,
        component: () => import('@/views/userSpaceManagement/addUserDataset')
      },
      {
        path: '/userManagement/userDatasetManagement/detailUserDataset',
        name: '数据集详情',
        hidden: true,
        component: () => import('@/views/userSpaceManagement/detailUserDataset')
      },
      // {
      //   path: '/userManagement/objectStorageSystem',
      //   name: '对象存储系统 OBS',
      //   component: () => import('@/views/userSpaceManagement/objectStorageSystem')
      // },
      // {
      //   path: '/userManagement/fileStorageSystemNFS',
      //   name: '文件存储系统 NFS',
      //   component: () => import('@/views/userSpaceManagement/fileStorageSystemNFS')
      // },
      // {
      //   path: '/userManagement/fileStorageSystemHDFS',
      //   name: '文件存储系统 HDFS',
      //   component: () => import('@/views/userSpaceManagement/fileStorageSystemHDFS')
      // },
      // {
      //   path: '/userManagement/fileStorageSystemLustre',
      //   name: '文件存储系统 Lustre',
      //   component: () => import('@/views/userSpaceManagement/fileStorageSystemLustre')
      // },

    ],
  },
  {
    path: '/home',
    name: '数据流转管理',
    component: () => import('@/components/Home'),
    children: [
      {
        path: '/home/migrationTaskManagement',
        name: '迁移任务管理',
        component: () => import('@/components/dataFlowManagement/components/migrationTaskManagement')
      },
      {
        path: '/home/migrationTaskManagement/addMigrationTask',
        name: '迁移任务增加',
        hidden: true,
        component: () => import('@/components/dataFlowManagement/components/addMigrationTask')
      },
      {
        path: '/home/migrationTemplateManagement',
        name: '迁移模板管理',
        component: () => import('@/components/dataFlowManagement/components/migrationTemplateManagement')
      },
      {
        path: '/home/migrationTaskManagement/addMigrationTemplate',
        name: '迁移模板新增',
        hidden: true,
        component: () => import('@/components/dataFlowManagement/components/addMigrationTemplate')
      },
      {
        path: '/home/viewOperationLog',
        name: '查看操作日志',
        component: () => import('@/components/dataFlowManagement/components/viewOperationLog')
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
